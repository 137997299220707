import xbytes from "xbytes";

//main site's service url
export const api = "https://app.classmateshop.co.in/graphql/";
//
//staging site's service url
// export const api = "https://cls2.classmateshop.co.in/graphql/";

// export const api = "https://ds.in.ngrok.io/graphql/";

//main site's Razorpay key
// export const Razorpay_Key = "rzp_live_b2P7PveFSj4wE9";
//staging site's Razorpay key
export const Razorpay_Key = "rzp_test_6Oe8wDVMElsoLo";

//ngrok
// export const Razorpay_Key = "rzp_test_8VImv62UL3Lmvc";

//main site's GA_key key
// export const GA_key = 'UA-57376375-16';

//staging site's GA_key key
export const GA_key = "UA-154312007-1";
// export const GA_key = 'UA-154664890-1';

// export const host_name = " https://ds.in.ngrok.io";

// export const host_name = "https://cls2.classmateshop.co.in";

export const host_name = "https://app.classmateshop.co.in";

export const long_book_multiplier = 4.636;

export const short_book_multiplier = 4;

// live
export const GoogleClientId =
  "415111460915-5gmj293g2eo2v0ihjm2d1g6thdkuonpg.apps.googleusercontent.com";

export const facebookAppId = "521315569215065";

// staging
// export const GoogleClientId = "215208803716-ghcn1lm8knl9b867n8u9icfb5iltpksu.apps.googleusercontent.com";
//
// export const facebookAppId = "1232624623806237";

export const state_list = [
  {
    id: "U3RhdGVzVHlwZTo4",
    name: "Andaman and Nicobar Islands",
    stateAbbr: "Andaman and Nicobar Isla",
  },
  {
    id: "U3RhdGVzVHlwZTozNQ==",
    name: "AP",
    stateAbbr: "Andra Pradesh",
  },
  {
    id: "U3RhdGVzVHlwZTozNA==",
    name: "Arunachal Pradesh",
    stateAbbr: "Arunachal Pradesh",
  },
  {
    id: "U3RhdGVzVHlwZTozMw==",
    name: "Assam",
    stateAbbr: "Assam",
  },
  {
    id: "U3RhdGVzVHlwZTozMg==",
    name: "Bihar",
    stateAbbr: "Bihar",
  },
  {
    id: "U3RhdGVzVHlwZTo3",
    name: "Chandigarh",
    stateAbbr: "Chandigarh",
  },
  {
    id: "U3RhdGVzVHlwZTozMQ==",
    name: "Chhattisgarh",
    stateAbbr: "Chhattisgarh",
  },
  {
    id: "U3RhdGVzVHlwZToy",
    name: "Dadar and Nagar Haveli",
    stateAbbr: "Dadar and Nagar Haveli",
  },
  {
    id: "U3RhdGVzVHlwZToz",
    name: "Daman and Diu",
    stateAbbr: "Daman and Diu",
  },
  {
    id: "U3RhdGVzVHlwZTo2",
    name: "Delhi",
    stateAbbr: "Delhi",
  },
  {
    id: "U3RhdGVzVHlwZTozMA==",
    name: "Goa",
    stateAbbr: "Goa",
  },
  {
    id: "U3RhdGVzVHlwZToyOQ==",
    name: "Gujarat",
    stateAbbr: "Gujarat",
  },
  {
    id: "U3RhdGVzVHlwZToyOA==",
    name: "Haryana",
    stateAbbr: "Haryana",
  },
  {
    id: "U3RhdGVzVHlwZToyNw==",
    name: "Himachal Pradesh",
    stateAbbr: "Himachal Pradesh",
  },
  {
    id: "U3RhdGVzVHlwZToyNg==",
    name: "Jammu and Kashmir",
    stateAbbr: "Jammu and Kashmir",
  },
  {
    id: "U3RhdGVzVHlwZToyNQ==",
    name: "Jharkhand",
    stateAbbr: "Jharkhand",
  },
  {
    id: "U3RhdGVzVHlwZToyNA==",
    name: "Karnataka",
    stateAbbr: "Karnataka",
  },
  {
    id: "U3RhdGVzVHlwZToyMw==",
    name: "Kerala",
    stateAbbr: "Kerala",
  },
  {
    id: "U3RhdGVzVHlwZTo1",
    name: "Lakshadeep",
    stateAbbr: "Lakshadeep",
  },
  {
    id: "U3RhdGVzVHlwZToyMg==",
    name: "Madya Pradesh",
    stateAbbr: "Madya Pradesh",
  },
  {
    id: "U3RhdGVzVHlwZToyMQ==",
    name: "Maharashtra",
    stateAbbr: "Maharashtra",
  },
  {
    id: "U3RhdGVzVHlwZToyMA==",
    name: "Manipur",
    stateAbbr: "Manipur",
  },
  {
    id: "U3RhdGVzVHlwZToxOQ==",
    name: "Meghalaya",
    stateAbbr: "Meghalaya",
  },
  {
    id: "U3RhdGVzVHlwZToxOA==",
    name: "Mizoram",
    stateAbbr: "Mizoram",
  },
  {
    id: "U3RhdGVzVHlwZToxNw==",
    name: "Nagaland",
    stateAbbr: "Nagaland",
  },
  {
    id: "U3RhdGVzVHlwZToxNg==",
    name: "Orissa",
    stateAbbr: "Orissa",
  },
  {
    id: "U3RhdGVzVHlwZTo0",
    name: "Pondicherry",
    stateAbbr: "Pondicherry",
  },
  {
    id: "U3RhdGVzVHlwZToxNQ==",
    name: "Punjab",
    stateAbbr: "Punjab",
  },
  {
    id: "U3RhdGVzVHlwZToxNA==",
    name: "Rajasthan",
    stateAbbr: "Rajasthan",
  },
  {
    id: "U3RhdGVzVHlwZToxMw==",
    name: "Sikkim",
    stateAbbr: "Sikkim",
  },
  {
    id: "U3RhdGVzVHlwZTozNg==",
    name: "TG",
    stateAbbr: "Telangana",
  },
  {
    id: "U3RhdGVzVHlwZTox",
    name: "TN",
    stateAbbr: "Tamil Nadu",
  },
  {
    id: "U3RhdGVzVHlwZToxMg==",
    name: "Tripura",
    stateAbbr: "Tripura",
  },
  {
    id: "U3RhdGVzVHlwZToxMQ==",
    name: "Uttaranchal",
    stateAbbr: "Uttaranchal",
  },
  {
    id: "U3RhdGVzVHlwZToxMA==",
    name: "Uttar Pradesh",
    stateAbbr: "Uttar Pradesh",
  },
  {
    id: "U3RhdGVzVHlwZTo5",
    name: "West Bengal",
    stateAbbr: "West Bengal",
  },
];

export const max_file_upload_size = xbytes.parseSize("10 MiB"); //file size in binary formate

export const max_file_upload_size_number = 10; //file size in binary formate
